​
<template>
    <div id="app">
      <div id="map" class="map">
        
      </div>
      <div style="position:fixed;background:rgba(256,256,256,0.8);width:800px;height:50px;top:0;left:100px;padding:20px;">
        <el-button type="primary" @click="getLocation">转移到当前位置</el-button>
        <el-button type="primary" @click="toStart">开始绘制</el-button>
        <el-button type="primary" @click="toEnd">停止绘制</el-button>
        <el-button type="primary">保存当前路线</el-button>
        <el-button type="primary" @click="clear">重置</el-button>
      </div>
    </div>
</template>
 
<script>
//导入基本模块
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Fill,Style,Stroke} from "ol/style";
//导入相关模块
import { Tile as TileLayer , Vector as VectorLayer } from 'ol/layer'
import { TileWMS ,Vector as VectorSource } from 'ol/source'
import { Select, Draw } from 'ol/interaction'
import XYZ from 'ol/source/XYZ'
export default {
  name: "olMapImageWMSDrawLine",
  data() {
    return {
      map: null, // map地图
      layer:null, //地图图层
      lineLayer:null, //线图层
      draw: null,
      lineSource:null,
      coordinate: [],
   };
  },
  mounted() {
    this.initMap();
    //this.testPro();
  },
  methods: {
    testPro(){
			const a = this.promisePro();
			a.then(()=>{
				if(this.isOk()){
					return true
				}
			}).then(()=>{
				this.getLocation()
			})

		},
		promisePro(){
			return new Promise((resolve, reject) => {
				if(true){
					resolve()
				}
			});
		},
		isOk(){
		    return 'geolocation' in navigator;
		},
    toLocation(){
      this.getLocation()
    },
    locationChange(arr){
						this.map.getView().animate({
						        center: arr,
						      
						        duration: 2000
						    });
					},
    getLocation(){
    var that = this;
			navigator.geolocation.getCurrentPosition(
				//TODO 获取成功时的处理
				(position)=> {
          console.log(position)
				    let timestamp = position.timestamp;
				    let coords = position.coords;
				    console.log(timestamp);
				    console.log(coords);
            if(coords){
              that.locationChange([coords.longitude,coords.latitude])
              // alert()
            }
				}, 
				 //TODO 获取失败时的处理
				(error)=> {
				    console.log(error);
				}, {
				    maximumAge: 0
				}
			)
			
		},
    toStart(){
      //调用绘图工具并传递类型为线，其他类型有Point,LineString,Polygon,Circle
      this.onAddInteraction('LineString')
    },
    toEnd(){
      this.draw.finishDrawing()
    },
    // 绘图工具
    onAddInteraction(type) {
        let self = this
        //勾绘矢量图形的类
        this.draw = new Draw({
            //source代表勾绘的要素属于的数据集
            source: self.lineSource,
            //type 表示勾绘的要素包含的 geometry 类型
            type: type
        })
 
        //绘制结束时触发的事件
        this.draw.on('drawend', function(e) {
            const geometry = e.feature.getGeometry()
            let pointArr = geometry.getCoordinates()
            self.coordinate.push(pointArr)
            console.log("self.coordinate="+self.coordinate);
            self.removeDraw()
        })
        self.map.addInteraction(this.draw)
    },
    //重新绘制
    clear() {
      this.map.removeInteraction(this.draw); //移除交互
      this.lineLayer.getSource().clear(); //清除图层上的所有要素
    
     
                // this.coordinate = []
                // this.drawLineLayer.getSource().clear()
                // //添加交互
                // this.map.addInteraction(this.selectTool)
                // switch(this.currentTool){            
                //     case 'LineString':
                //         this.onAddInteraction('LineString')
                //         break;
                //     case 'Polygon':
                //        this.onAddInteraction('Polygon')
                //         break;
                //     case 'Point':
                //       this.onAddInteraction('Point')
                //         break;
                // }                          
            },
    //删除交互
    removeDraw() {
        this.map.removeInteraction(this.draw)
    },
    initMap() {
      //地图图层
      this.layer = new TileLayer({
        source: new XYZ({
          //不能设置为0，否则地图不展示。
       
          url: "https://map.guanxunlot.com:8083/map/googlemaps/hybrid/{z}/{x}/{y}.jpg",
        }),
      });
 
      //线的图层
      this.lineSource = new VectorSource({ wrapX: false });
      this.lineLayer = new VectorLayer({
          source: this.lineSource,
      });
 
      this.map = new Map({
        //地图容器ID
        target: "map",
        //引入地图
        layers: [this.layer,this.lineLayer],
        view: new View({
          //地图中心点
          center: [125.07935, 46.7022],
          zoom: 11,
          projection: 'EPSG:4326' // 坐标系
          // minZoom:6, // 地图缩放最小级别
          // maxZoom:19,
        }),
      });
 
      // 获取点击地图的坐标(选中样式)
      let selectedStyle = new Style({
          fill: new Fill({
              color: 'rgba(1, 210, 241, 0.2)'
          }),
          stroke: new Stroke({
              color: 'yellow',
              width: 4
          })
      })
      // 选择线的工具类
      this.selectTool = new Select({
          multi: true,
          hitTolerance: 10, // 误差
          style: selectedStyle // 选中要素的样式
      })
      //添加交互
      this.map.addInteraction(this.selectTool)
      
 
    },
  },
};
</script>
 
<style>
html,body{
  width:100%;
  height:100%;
}
*{
  margin:0;
  padding:0;
}
#app{
  width: 100%;
  height: 100%;
}
.map {
  width: 100%;
  height: 100%;
}
</style>
 
​